import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AgendaCalendar = ({ appointments, onAppointmentChange, setSelectedAppointment }) => {
    const [startDate, setStartDate] = useState(new Date());

    const handleDateChange = (date) => {
        setStartDate(date);
        onAppointmentChange(date);
        setSelectedAppointment(null); // Reiniciar la cita seleccionada al cambiar la fecha
    };

    const isAppointmentSlotAvailable = (date) => {
        if (!appointments || appointments.length === 0) {
            return true; // Retorna true si no hay citas o appointments es undefined/null
        }

        const existingAppointment = appointments.find(appt => appt.getTime() === date.getTime());
        return !existingAppointment; // Retorna true si no hay cita en este horario
    };

    const handleTimeClick = (hour) => {
        const selectedDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), hour);
        setSelectedAppointment(selectedDate);
    };

    const renderDay = (date) => {
        const isAvailable = isAppointmentSlotAvailable(date);

        return (
            <div className={`p-1 text-center ${isAvailable ? 'bg-green-200 cursor-pointer' : 'bg-red-200'}`}>
                {date.getDate()}
            </div>
        );
    };

    const renderTimeSlots = () => {
        const timeSlots = [];

        for (let hour = 9; hour < 17; hour++) {
            const slotDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), hour);
            const isAvailable = isAppointmentSlotAvailable(slotDate);

            timeSlots.push(
                <div
                    key={hour}
                    className={`p-2 border ${isAvailable ? 'border-green-500 cursor-pointer' : 'border-red-500'}`}
                    onClick={() => handleTimeClick(hour)}
                >
                    {`${hour}:00 ${isAvailable ? 'Disponible' : 'No disponible'}`}
                </div>
            );
        }

        return (
            <div className="grid grid-cols-4 gap-2 mt-4">
                {timeSlots}
            </div>
        );
    };

    return (
        <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-center">Agenda de Citas</h2>
            <div className="flex justify-center mb-4">
                <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    inline
                    calendarClassName="w-auto"
                    dayClassName={(date) => renderDay(date)}
                />
            </div>
            <div className="text-center">
                <h3 className="text-lg font-semibold mb-2">Horarios disponibles</h3>
                {renderTimeSlots()}
            </div>
        </div>
    );
};

export default AgendaCalendar;
