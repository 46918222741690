import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-8 mt-auto">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="md:text-left mb-4 md:mb-0">
                        <p className="mb-2">Síguenos en nuestras redes sociales:</p>
                        <div className="flex items-center space-x-4">
                            <a href="https://facebook.com/serbizcar" target="_blank" rel="noopener noreferrer" className="flex items-center text-white">
                                <img src="/logos/face.png" alt="Facebook" className="h-6 w-6 mr-1" />
                                <span>serbizcar</span>
                            </a>
                            <a href="https://instagram.com/serbizcar" target="_blank" rel="noopener noreferrer" className="flex items-center text-white">
                                <img src="/logos/insta.png" alt="Instagram" className="h-6 w-6 mr-1" />
                                <span>serbizcar</span>
                            </a>
                        </div>
                    </div>
                    <p className="text-xs text-right italic">
                        "Mira que te mando que te esfuerces y seas valiente; <br />
                        no temas ni desmayes, porque Jehová tu Dios estará contigo <br />
                        en dondequiera que vayas." - Josué 1:9
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
