// src/services/CotizadorService.js
import axios from 'axios';

const BASE_URL = 'https://serbizcar-ws-prod-ntsedlc2aq-uc.a.run.app/api';

// Obtener todas las marcas
const getBrands = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/brands`);
        return response.data;
    } catch (error) {
        console.error('Error fetching brands:', error);
        throw error;
    }
};

// Obtener todos los modelos por brandId
const getModelsByBrandId = async (brandId) => {
    try {
        const response = await axios.get(`${BASE_URL}/models/brand/${brandId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching models for brandId ${brandId}:`, error);
        throw error;
    }
};

// Agregar nueva marca
const addBrand = async (name) => {
    try {
        const response = await axios.post(`${BASE_URL}/brands`, { name });
        return response.data;
    } catch (error) {
        console.error('Error adding brand:', error);
        throw error;
    }
};

// Agregar nuevo modelo
const addModel = async (brandId, name) => {
    try {
        const response = await axios.post(`${BASE_URL}/models`, { brand: brandId, name });
        return response.data;
    } catch (error) {
        console.error('Error adding model:', error);
        throw error;
    }
};

// Actualizar marca por ID
const updateBrand = async (brandId, updatedBrand) => {
    try {
        const response = await axios.put(`${BASE_URL}/brands/${brandId}`, updatedBrand);
        return response.data;
    } catch (error) {
        console.error(`Error updating brand ${brandId}:`, error);
        throw error;
    }
};

// Eliminar marca por ID
const deleteBrand = async (brandId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/brands/${brandId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting brand ${brandId}:`, error);
        throw error;
    }
};

// Actualizar modelo por ID
const updateModel = async (modelId, updatedModel) => {
    try {
        const response = await axios.put(`${BASE_URL}/models/${modelId}`, updatedModel);
        return response.data;
    } catch (error) {
        console.error(`Error updating model ${modelId}:`, error);
        throw error;
    }
};

// Eliminar modelo por ID
const deleteModel = async (modelId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/models/${modelId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting model ${modelId}:`, error);
        throw error;
    }
};

export default {
    getBrands,
    getModelsByBrandId,
    addBrand,
    addModel,
    updateBrand,
    deleteBrand,
    updateModel,
    deleteModel,
};
