import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const BrandCarousel = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    return (
        <div className="bg-white py-8">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-blue-900 mb-6 text-center">Marcas con las que trabajamos</h2>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    infinite={true}
                    containerClass="carousel-container"
                    itemClass="carousel-item"
                >
                    <div className="flex justify-center items-center h-full">
                        <img src="/logos/marca1.png" alt="Marca 1" className="h-12 md:h-16 lg:h-20 xl:h-24 mx-auto" />
                    </div>
                    <div className="flex justify-center items-center h-full">
                        <img src="/logos/marca2.png" alt="Marca 2" className="h-12 md:h-16 lg:h-20 xl:h-24 mx-auto" />
                    </div>
                    <div className="flex justify-center items-center h-full">
                        <img src="/logos/marca3.png" alt="Marca 3" className="h-12 md:h-16 lg:h-20 xl:h-24 mx-auto" />
                    </div>
                    <div className="flex justify-center items-center h-full">
                        <img src="/logos/marca4.png" alt="Marca 4" className="h-12 md:h-16 lg:h-20 xl:h-24 mx-auto" />
                    </div>
                    <div className="flex justify-center items-center h-full">
                        <img src="/logos/marca5.png" alt="Marca 5" className="h-12 md:h-16 lg:h-20 xl:h-24 mx-auto" />
                    </div>
                    {/* Agrega más imágenes de marcas según sea necesario */}
                </Carousel>
            </div>
        </div>
    );
};

export default BrandCarousel;
