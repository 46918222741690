import React from 'react';

const Modal = ({ onClose, children }) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onClose}></div>
            <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="py-4 px-6">
                    <div className="flex justify-between items-center pb-3">
                        <h2 className="text-2xl font-bold">Confirmación</h2>
                        <button className="focus:outline-none" onClick={onClose}>
                            <svg className="h-6 w-6 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="py-2">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
