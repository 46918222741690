import React from 'react';
import WhatsappComponent from '../components/tools/WhatsappComponent';

const Contact = () => {
    const handleFacebookClick = () => {
        window.open('https://facebook.com/serbizcar', '_blank');
    };

    const handleInstagramClick = () => {
        window.open('https://instagram.com/serbizcar', '_blank');
    };

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/50247499785', '_blank');
    };

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4">
            <div className="container mx-auto max-w-4xl bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Contáctanos</h1>
                <p className="text-gray-700 mb-6 text-center">
                    Si tienes alguna pregunta o necesitas más información, no dudes en ponerte en contacto con nosotros.
                </p>

                {/* Redes Sociales */}
                <div className="flex flex-col items-center space-y-6 mb-6 sm:flex-row sm:space-y-0 sm:space-x-8 justify-center">
                    {/* Facebook */}
                    <div className="flex items-center cursor-pointer" onClick={handleFacebookClick}>
                        <img
                            src="/logos/face.png"
                            alt="Facebook"
                            className="w-12 h-12 mr-2"
                        />
                        <span className="text-gray-700 font-medium">Serbizcar</span>
                    </div>

                    {/* Instagram */}
                    <div className="flex items-center cursor-pointer" onClick={handleInstagramClick}>
                        <img
                            src="/logos/insta.png"
                            alt="Instagram"
                            className="w-12 h-12 mr-2"
                        />
                        <span className="text-gray-700 font-medium">Serbizcar</span>
                    </div>

                    {/* WhatsApp */}
                    <div className="flex items-center cursor-pointer" onClick={handleWhatsAppClick}>
                        <img
                            src="/logos/whatsapp.png"
                            alt="WhatsApp"
                            className="w-12 h-12 mr-2"
                        />
                        <span className="text-gray-700 font-medium">Serbizcar</span>
                    </div>
                </div>
            </div>

            {/* Botón flotante de WhatsApp */}
            <WhatsappComponent />
        </div>
    );
};

export default Contact;
