import React, { useState } from 'react';
import Modal from './Modal'; // Asumiendo que tienes un componente Modal genérico

const EditarModal = ({ item, onSave, onClose }) => {
    const [editedName, setEditedName] = useState(item.name);

    const handleSave = () => {
        onSave(item._id, editedName);
        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <div className="p-4">
                <h2 className="text-lg font-bold mb-2">Editar {item.type === 'brand' ? 'Marca' : 'Modelo'}</h2>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder={`Nuevo nombre de ${item.type === 'brand' ? 'marca' : 'modelo'}`}
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                />
                <div className="flex justify-end mt-4">
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mr-2"
                        onClick={handleSave}
                    >
                        Guardar
                    </button>
                    <button
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditarModal;
