import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Mostrar la flecha cuando el usuario ha scrollado más allá de 120px
    const toggleVisibility = () => {
        if (window.pageYOffset > 120) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Función para desplazarse hacia arriba
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Agregar listener para scroll
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className="fixed top-1/2 right-4 transform translate-y-52 -translate-y-1/2 z-50">
            {isVisible &&
                <button
                    onClick={scrollToTop}
                    className="bg-blue-900 text-yellow-400 p-3 rounded-full shadow-lg focus:outline-none"
                >
                    <FaArrowUp />
                </button>
            }
        </div>
    );
};

export default ScrollToTop;
