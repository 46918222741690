import React from 'react';
import Modal from './Modal'; // Asumiendo que tienes un componente Modal genérico

const EliminarModal = ({ item, onDelete, onClose }) => {
    const handleDelete = () => {
        onDelete(item._id);
        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <div className="p-4">
                <h2 className="text-lg font-bold mb-2">Confirmar Eliminación</h2>
                <p className="mb-4">¿Estás seguro de que deseas eliminar {item.type === 'brand' ? 'la marca' : 'el modelo'} "{item.name}"?</p>
                <div className="flex justify-end">
                    <button
                        className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 mr-2"
                        onClick={handleDelete}
                    >
                        Eliminar
                    </button>
                    <button
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EliminarModal;
