import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CarouselComponent = () => {
    return (
        <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            stopOnHover={false}
            className="h-72"
        >
            <div className="relative h-72 flex items-center justify-center">
                <img src="/images/imagen1.jpg" alt="Imagen 1" className="object-cover h-full w-full" />
                <p className="text-yellow-400 text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold absolute">
                    Somos los mejores en servicios a domicilio para tu vehículo
                </p>
            </div>
            <div className="relative h-72 flex items-center justify-center">
                <img src="/images/imagen2.jpg" alt="Imagen 2" className="object-cover h-full w-full" />
                <p className="text-yellow-400 text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold absolute">
                    Todo hasta la puerta de tu casa
                </p>
            </div>
            <div className="relative h-72 flex items-center justify-center">
                <img src="/images/imagen3.jpg" alt="Imagen 3" className="object-cover h-full w-full" />
                <p className="text-yellow-400 text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold absolute">
                    Mano de obra y productos de alta calidad
                </p>
            </div>
        </Carousel>
    );
};

export default CarouselComponent; // Asegúrate de exportar correctamente el componente
