import React from 'react';

const WhatsappComponent = () => {
    return (
        <a
            href="https://wa.me/50247499785"
            className="fixed bottom-4 right-4 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 focus:outline-none"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat with us on WhatsApp"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
            >
                <path d="M12 0C5.37 0 0 5.37 0 12c0 2.12.55 4.11 1.61 5.88L.05 24l6.31-1.65C8.85 23.45 10.42 24 12 24c6.63 0 12-5.37 12-12S18.63 0 12 0zm0 22.63c-1.47 0-2.93-.38-4.24-1.1l-.3-.16-4.7 1.22 1.26-4.58-.2-.3C2.23 15.32 1.75 13.68 1.75 12c0-5.67 4.58-10.25 10.25-10.25S22.25 6.33 22.25 12 17.67 22.25 12 22.25zm4.9-6.44l-.29-.15c-.3-.15-1.77-.86-2.04-.96-.27-.1-.46-.15-.65.15-.19.29-.75.96-.93 1.16-.18.2-.34.22-.64.07-.3-.15-1.26-.46-2.4-1.48-.88-.79-1.48-1.76-1.65-2.06-.17-.29-.02-.45.13-.6.14-.14.3-.34.45-.51.15-.17.2-.29.3-.49.1-.2.05-.37-.03-.51-.08-.14-.65-1.58-.9-2.2-.24-.58-.49-.5-.68-.51-.18-.01-.37-.01-.56-.01-.2 0-.51.07-.78.37-.27.29-1.02 1-1.02 2.43 0 1.43 1.04 2.81 1.19 3.01.15.2 2.03 3.1 4.92 4.34.69.3 1.23.48 1.65.62.69.22 1.31.19 1.8.12.55-.08 1.77-.72 2.02-1.41.25-.69.25-1.28.17-1.41-.08-.14-.27-.22-.56-.37z"/>
            </svg>
        </a>
    );
};

export default WhatsappComponent;
