import React from 'react';
import { Link } from 'react-router-dom';
import Carrousel from "../components/sections/Carrousel";
import ScrollToTop from "../components/tools/ScrollToTop";
import WhatsappComponent from "../components/tools/WhatsappComponent";
import BrandCarousel from "../components/sections/BrandCarousel"; // Importa el componente BrandCarousel

const Home = () => {
    return (
        <div className="relative">
            {/* Contenedor del Carousel */}
            <div className="h-72">
                <Carrousel />
            </div>

            {/* Franja azul con "Nuestros servicios" */}
            <div className="bg-gray-900 py-4 text-center">
                <h2 className="text-yellow-400 text-3xl font-bold">Nuestros servicios</h2>
            </div>

            {/* Iconos con texto */}
            <div className="bg-white py-8 text-center">
                <div className="flex flex-col items-center justify-center md:flex-row md:justify-center">
                    <Link to="/ourServices" className="flex flex-col items-center mx-4 mb-8 md:w-auto">
                        <img src="/icons/tools.png" alt="Icono Herramienta" className="h-12 w-12 mb-2" />
                        <p className="text-gray-800">Servicio mayor/menor</p>
                    </Link>
                    <Link to="/ourServices" className="flex flex-col items-center mx-4 mb-8 md:w-auto">
                        <img src="/icons/oil.png" alt="Icono Check Engine" className="h-12 w-12 mb-2" />
                        <p className="text-gray-800">Cambio de aceite</p>
                    </Link>
                    <Link to="/ourServices" className="flex flex-col items-center mx-4 mb-8 md:w-auto">
                        <img src="/icons/brake.png" alt="Icono Caliper de freno" className="h-12 w-12 mb-2" />
                        <p className="text-gray-800">Cambios de pastillas</p>
                    </Link>
                    <Link to="/ourServices" className="flex flex-col items-center mx-4 mb-8 md:w-auto">
                        <img src="/icons/wheel.png" alt="Icono Llanta" className="h-12 w-12 mb-2" />
                        <p className="text-gray-800">Mantenimientos preventivos</p>
                    </Link>
                </div>
            </div>

            {/* Sección "¿Quiénes somos?" */}
            <div className="bg-white py-8 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">¿Quiénes somos?</h2>
                    <p className="text-gray-700 max-w-3xl mx-auto">
                        Somos una empresa dedicada a los servicios preventivos de vehículos a domicilio, ofreciendo
                        comodidad y calidad a nuestros clientes para mantener sus vehículos en óptimas condiciones...{' '}
                        <Link to="/about" className="text-blue-500 hover:underline">Ver más</Link>
                    </p>
                </div>
            </div>

            {/* Sección "¿Por qué elegirnos?" */}
            <div className="bg-gray-100 py-8 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6">¿Por qué elegirnos?</h2>
                    <p className="text-gray-700 max-w-3xl mx-auto">
                        Realiza tus servicios automotrices desde la comodidad de tu hogar con profesionales capacitados.
                        Ofrecemos garantías en todos nuestros trabajos para tu tranquilidad.
                    </p>
                </div>
            </div>

            {/* Carousel de Marcas */}
            <BrandCarousel />

            {/* Sección "Te hacemos la vida más fácil" */}
            <div className="bg-gray-200 py-8">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-blue-900 mb-6 text-center">Te hacemos la vida más fácil</h2>
                    <p className="text-gray-700 max-w-3xl mx-auto">
                        Cotiza y agenda tu servicio de manera práctica y sencilla sin tener que llamar a nadie, esperar
                        respuestas o complicarte la vida. En Serbizcar, simplificamos tu experiencia automotriz con nuestro
                        <Link to="/quote" className="text-blue-500 hover:underline">cotizador en línea</Link>.
                    </p>
                </div>
            </div>

            {/* Componente de flecha hacia arriba */}
            <ScrollToTop />

            {/* Botón flotante de WhatsApp */}
            <WhatsappComponent />
        </div>
    );
};

export default Home;
