import React from 'react';

const OurServices = () => {
    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4 flex flex-col items-center">
            <h1 className="text-4xl font-bold text-blue-900 mb-6 text-center">Nuestros Servicios</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center items-center">
                {/* Servicio Mayor */}
                <div className="flex flex-col items-center justify-center p-4 rounded-lg bg-white shadow-md text-center">
                    <img
                        src="/icons/tools.png"
                        alt="Servicio Mayor"
                        className="w-20 h-20"
                    />
                    <h3 className="text-xl font-semibold text-blue-900 mt-4 mb-2">Servicio Mayor</h3>
                    <p className="text-gray-700">
                        Realizamos mantenimientos completos y revisión detallada de tu vehículo.
                    </p>
                </div>

                {/* Servicio Menor */}
                <div className="flex flex-col items-center justify-center p-4 rounded-lg bg-white shadow-md text-center">
                    <img
                        src="/icons/oil.png"
                        alt="Servicio Menor"
                        className="w-20 h-20"
                    />
                    <h3 className="text-xl font-semibold text-blue-900 mt-4 mb-2">Servicio Menor</h3>
                    <p className="text-gray-700">
                        Mantenimientos básicos y cambios de aceite para mantener tu vehículo en óptimas condiciones.
                    </p>
                </div>

                {/* Cambio de Frenos */}
                <div className="flex flex-col items-center justify-center p-4 rounded-lg bg-white shadow-md text-center">
                    <img
                        src="/icons/brake.png"
                        alt="Cambio de Frenos"
                        className="w-20 h-20"
                    />
                    <h3 className="text-xl font-semibold text-blue-900 mt-4 mb-2">Cambio de Frenos</h3>
                    <p className="text-gray-700">
                        Reparación y cambio de frenos para garantizar la seguridad y el rendimiento de tu vehículo.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
